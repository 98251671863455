import { signalStore, withMethods } from '@ngrx/signals';
import { effect, inject, untracked } from '@angular/core';
import { ThemeService } from 'ng2-charts';
import { LayoutStore, Theme } from '../stores';
import { ChartOptions } from 'chart.js';

export const Ng2ChartsThemingStore = signalStore(
  { providedIn: 'root' },
  withMethods(state => {

    const ng2ChartsThemeService = inject(ThemeService);
    const layoutStore = inject(LayoutStore);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const themeChangedEffect = effect(() => {
      const theme = layoutStore.theme();
      untracked(() => {
        updateChartColorScheme(theme);
      });
    });

    const updateChartColorScheme = (theme: Theme) => {
      let overrides: ChartOptions;

      if (theme == 'dark') {
        overrides = {
          doughnut: {
            datasets: {
              backgroundColor: ['gray', 'green', 'orange', 'red', 'gray', 'green', 'orange', 'red']
            }
          },
          datasets: {
            doughnut: {
              borderColor: 'green',
              backgroundColor: ['gray', 'green', 'orange', 'red']
            }
          }
          , scales: {
            x: {
              ticks: {
                color: 'white'
              },
              grid: {
                color: 'rgba(255,255,255,0.1)'
              }
            },
            y: {
              ticks: {
                color: 'white'
              },
              grid: {
                color: 'rgba(255,255,255,0.1)'
              }
            }
          },
          plugins: {
            legend: {
              labels: {
                color: 'white'
              }
            }
            /*datalabels: {
              color: 'white',
            },*/
          }
        };
      } else {
        overrides = {
          datasets: {
            doughnut: {
              backgroundColor: ['pink', 'green', 'orange', 'red']
            }
          },
          scales: undefined,
          plugins: undefined
        };
      }

      ng2ChartsThemeService.setColorschemesOptions(overrides);
    };


    return {};
  })
);
