import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { effect, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';


export type Theme = 'light' | 'dark'
export type PreferredTheme = 'light' | 'dark' | 'auto'
export type ScreenSize = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'

export const screenSizeMap: { [key in ScreenSize]: number } = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};

export type LayoutState = {
  theme: Theme;
  preferredTheme: PreferredTheme;
  sidebarLgCollapsed: boolean,
  sidebarMdCollapsed: boolean,
  sidebarSmCollapsed: boolean
  screenSize: ScreenSize
}

const initialState: LayoutState = {
  theme: 'dark',
  preferredTheme: 'auto',
  sidebarLgCollapsed: false,
  sidebarMdCollapsed: true,
  sidebarSmCollapsed: true,
  screenSize: 'md'
};

export const LayoutStore = signalStore(
  withState(initialState),
  withMethods(state => {

    const router = inject(Router);


    const calculateScreenSize = (): void => {
      const screenSize = window.innerWidth;
      if (screenSize >= screenSizeMap.xxl) {
        patchState(state, { screenSize: 'xxl' });
      } else if (screenSize >= screenSizeMap.xl) {
        patchState(state, { screenSize: 'xl' });
      } else if (screenSize >= screenSizeMap.lg) {
        patchState(state, { screenSize: 'lg' });
      } else if (screenSize >= screenSizeMap.md) {
        patchState(state, { screenSize: 'md' });
      } else if (screenSize >= screenSizeMap.sm) {
        patchState(state, { screenSize: 'sm' });
      } else if (screenSize >= screenSizeMap.xs) {
        patchState(state, { screenSize: 'xs' });
      }
    };

    const initialize = () => {
      window.addEventListener('resize', calculateScreenSize);

      router.events.pipe(
      ).subscribe((e) => {
        if (e instanceof NavigationEnd) {
          patchState(state, { sidebarSmCollapsed: true });
        }
      });

      calculateScreenSize();
    };

    const cleanup = () => {
      window.removeEventListener('resize', calculateScreenSize);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const layoutChangedEffect = effect(() => {
      const screenSize = state.screenSize();
      const sidebarLgCollapsed = state.sidebarLgCollapsed();
      const sidebarMdCollapsed = state.sidebarMdCollapsed();
      const sidebarSmCollapsed = state.sidebarSmCollapsed();
      console.debug('[LayoutStore] - layoutChangedEffect', state.screenSize());
      switch (screenSize) {
        case 'xxl':
        case 'xl':
          document.documentElement.setAttribute('data-sidebar-state', sidebarLgCollapsed ? 'condensed' : 'full');
          break;
        case 'lg':
        case 'md':
          document.documentElement.setAttribute('data-sidebar-state', sidebarMdCollapsed ? 'condensed' : 'full');
          break;
        case 'sm':
        case 'xs':
          document.documentElement.setAttribute('data-sidebar-state', sidebarSmCollapsed ? 'hidden' : 'overlay');
          break;
      }
      //document.documentElement.setAttribute('data-sidebar-mobile', state.sidebarMobileActive() ? 'open' : '');
    });



    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const themeChangedEffect = effect(() => {
      console.debug('[LayoutStore] - themeChangedEffect', state.theme());
      document.documentElement.setAttribute('data-bs-theme', state.theme());
    });

    const toggleSidebar = () => {
      const screenSize = state.screenSize();
      console.debug('[LayoutStore] - layoutChangedEffect', state.screenSize());
      switch (screenSize) {
        case 'xxl':
        case 'xl':
          patchState(state, {
            sidebarLgCollapsed: !state.sidebarLgCollapsed()
          });
          break;
        case 'md':
        case 'lg':
          patchState(state, {
            sidebarMdCollapsed: !state.sidebarMdCollapsed()
          });
          break;
        case 'sm':
        case 'xs':
          patchState(state, {
            sidebarSmCollapsed: !state.sidebarSmCollapsed()
          });
          break;
      }
    };

    const toggleTheme = () => {
      patchState(state, {
        theme: state.theme() === 'light' ? 'dark' : 'light'
      });
    };

    return {
      initialize,
      cleanup,
      toggleSidebar,
      toggleTheme
    };
  }),
  withHooks({
    onInit({ initialize }) {
      initialize();
    },
    onDestroy({ cleanup }) {
      cleanup();
    }
  })
);
