/// <reference types="@types/googlemaps" />
import { computed, inject, Injectable, Signal } from '@angular/core';
import { LayoutStore } from '@twist/ui-core';

import {googleMapsDarkStyles} from '../settings/google-maps/google-maps-styles-dark';
import MapTypeStyle = google.maps.MapTypeStyle;
import { googleMapsLightStyles } from '../settings/google-maps/google-maps-styles-light';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsStore {
  private _layoutStore = inject(LayoutStore);

  gmapsStyles: Signal<MapTypeStyle[]> = computed(() => {
    console.debug('[gmapsStore] - get styles', googleMapsDarkStyles);
    if (this._layoutStore.theme() == 'dark') {
      return googleMapsDarkStyles;
    }
    return googleMapsLightStyles;
  });

}
